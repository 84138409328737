var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('extrato-item',{attrs:{"id":_vm.cancelados ? 'cancelados' : 'nao-cancelados',"headers":_vm.headers,"items":_vm.cancelados
      ? _vm.movimentacoesServicosCancelados
      : _vm.movimentacoesServicosNaoCancelados,"title":_vm.title,"negative":_vm.cancelados},scopedSlots:_vm._u([{key:"valor",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toReal(item.valor))+" ")]}},{key:"forma_pagamento",fn:function(ref){
      var item = ref.item;
return [_c('v-select',{staticClass:"pa-0 ma-0 forma_pagamento",attrs:{"single-line":"","append-icon":"","item-text":"descricao","item-value":"public_id","return-object":"","items":_vm.formasPagamento},on:{"change":function($event){return _vm.$emit('trocarFormaPagemento', item)}},model:{value:(item.forma_pagamento),callback:function ($$v) {_vm.$set(item, "forma_pagamento", $$v)},expression:"item.forma_pagamento"}})]}},{key:"transportadora",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"autocomplete"},[_c('v-autocomplete',{staticClass:"pa-0 ma-0 forma_pagamento",attrs:{"single-line":"","append-icon":"","item-text":function (item) { return item.business_name + " - " + (item.cnpj || item.cpf); },"item-value":"public_id","return-object":"","items":[item.transportadora].concat(
            _vm.transportadoras.filter(
              function (t) { return t.transportadora_id != item.transportadora.transportadora_id; }
            )
          )},on:{"click":function($event){return _vm.$emit('setUpTransportadoras', item)},"keyup":function($event){return _vm.customChange($event, _vm.handleGetTransportadoras)},"change":function($event){return _vm.$emit('trocarTransportadora', item)}},model:{value:(item.transportadora),callback:function ($$v) {_vm.$set(item, "transportadora", $$v)},expression:"item.transportadora"}})],1)]}},{key:"data",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toLocaleDateTimeString")(item.data))+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }