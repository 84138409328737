<template>
  <v-row>
    <v-col>
      <v-card>
        <v-card-title>Totais</v-card-title>
        <v-divider />
        <v-card-text>
          <extrato-total-forma-pagamento
            title="Serviços"
            :totalAgrupadoPorFormaPagamento="totalServicosPorFormaPagamento"
            :total="totalNaoCancelado"
          />
          <v-divider class="my-4" />
          <extrato-total-forma-pagamento-cancelado
            title="Serviços Cancelados"
            :totalAgrupadoPorFormaPagamento="totalServicosPorFormaPagamentoCancelados"
            :total="totalCancelado"
          />
          <v-divider class="my-4" />
          <div class="d-flex justify-space-between">
            <h2 class="text-subtitle-1 primary--text">Total de serviços</h2>
            <p
              :class="'positive-value'"
            >
              {{ toReal((totalNaoCancelado)) }}
            </p>
          </div>
          <div class="d-flex justify-space-between">
            <h2 class="text-subtitle-1 primary--text">Total de serviços cancelados</h2>
            <p
              :class="'negative-value'"
            >
              {{ toReal((totalCancelado)) }}
            </p>
          </div>
          <div class="d-flex justify-space-between">
            <h2 class="text-subtitle-1 primary--text">Total Em Caixa</h2>
            <p
              :class="
                (totalNaoCancelado - totalCancelado) >= 0
                  ? 'positive-value'
                  : 'negative-value'
              "
            >
              {{ toReal((totalNaoCancelado - totalCancelado)) }}
            </p>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import { toReal } from '@/mixins/convertion'

import ExtratoTotalFormaPagamento from './ExtratoTotalFormaPagamento.vue'

export default {
  name: 'ExtratoTotal',
  mixins: [toReal],
  components: { ExtratoTotalFormaPagamento },
  computed: {
    ...mapGetters('caixa', [
      'totalServicosPorFormaPagamento',
      'totalManuaisPorFormaPagamento',
      'totalServicosPorFormaPagamentoCancelados',
      'totalNaoCancelado',
      'totalCancelado',
    ]),
    ...mapState('caixa', ['caixaExtrato']),
  },
}
</script>
