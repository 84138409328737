div
<template>
  <extrato-item
    :id="cancelados ? 'cancelados' : 'nao-cancelados'"
    :headers="headers"
    :items="
      cancelados
        ? movimentacoesServicosCancelados
        : movimentacoesServicosNaoCancelados
    "
    :title="title"
    :negative="cancelados"
  >
    <template #valor="{ item }">
      {{ toReal(item.valor) }}
    </template>
    <template #forma_pagamento="{ item }">
      <v-select
        single-line
        append-icon=""
        v-model="item.forma_pagamento"
        item-text="descricao"
        item-value="public_id"
        return-object
        :items="formasPagamento"
        class="pa-0 ma-0 forma_pagamento"
        @change="$emit('trocarFormaPagemento', item)"
      />
    </template>
    <template #transportadora="{ item }">
      <div class="autocomplete">
        <v-autocomplete
          single-line
          append-icon=""
          v-model="item.transportadora"
          :item-text="
            (item) => item.business_name + ` - ${item.cnpj || item.cpf}`
          "
          item-value="public_id"
          return-object
          :items="
            [item.transportadora].concat(
              transportadoras.filter(
                (t) =>
                  t.transportadora_id != item.transportadora.transportadora_id
              )
            )
          "
          class="pa-0 ma-0 forma_pagamento"
          @click="$emit('setUpTransportadoras', item)"
          @keyup="customChange($event, handleGetTransportadoras)"
          @change="$emit('trocarTransportadora', item)"
        />
      </div>
    </template>
    <template #data="{ item }">
      {{ item.data | toLocaleDateTimeString }}
    </template>
  </extrato-item>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import { toReal, toLocaleDateTimeString } from '@/mixins/convertion'

import { customChange } from '@/mixins/autocomplete.js'

import ExtratoItem from './ExtratoItem'

export default {
  name: 'ExtratoTriagem',
  props: {
    cancelados: {
      type: Boolean,
    },
  },
  mixins: [toReal, customChange],
  filters: { toLocaleDateTimeString },
  components: { ExtratoItem },
  data() {
    return {
      headers: [
        { text: 'Senha', value: 'senha' },
        { text: 'Serviço', value: 'servico' },
        { text: 'Valor', value: 'valor' },
        { text: 'Forma Pagamento', value: 'forma_pagamento' },
        { text: 'Peso (KG)', value: 'peso' },
        { text: 'Data', value: 'data' },
        { text: 'Placa Cavalo', value: 'placa_cavalo' },
        { text: 'Motorista', value: 'motorista' },
        { text: 'Transportadora', value: 'transportadora' },
        { text: 'Terminal', value: 'terminal' },
      ],
    }
  },
  computed: {
    ...mapGetters('caixa', [
      'movimentacoesServicosCancelados',
      'movimentacoesServicosNaoCancelados',
    ]),
    ...mapState('transportadoras', ['transportadoras']),
    ...mapState('formaPagamento', ['formasPagamento']),

    title() {
      return this.cancelados ? 'Serviços Cancelados' : 'Serviços'
    },
  },
  methods: {
    async handleGetTransportadoras(event) {
      const value = event.target.value
      this.$emit('buscarTransportadoras', value)
    },
  },
}
</script>

<style>
.v-select__selection--comma {
  overflow: visible !important;
}
.autocomplete {
  width: 500px;
}
#nao-cancelados .v-card,
#cancelados .v-card {
  border-width: 3px;
  border-style: solid;
  border-radius: 15px;
}

#cancelados .v-card {
  border-color: #00113a;
}
#nao-cancelados .v-card {
  border-color: #00aa9e;
}
.forma_pagamento {
  height: 32px;
}
/* solo flat */
/* .forma_pagamento {
  max-height: 48px;
}   */

.forma_pagamento .v-input__slot:before {
  border-style: none !important;
}
.forma_pagamento .v-input__slot:after {
  border-style: none !important;
}
</style>
