<template>
  <div>
    <template v-if="typeof message.message === 'string'">
      <v-snackbar
        top
        v-model="message.show"
        :color="message.color"
        elevation="12"
        timeout="10000"
      >
        {{ message.message }}

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="toggleMessage"> Fechar </v-btn>
        </template>
      </v-snackbar>
    </template>
    <template v-else-if="typeof message.message === 'object'">
      <v-snackbar
        top
        v-model="message.show"
        :color="message.color"
        elevation="12"
        timeout="10000"
      >
        <div v-for="(values, name) in message.message" :key="name">
          <template v-if="typeof values === 'object'">
            <div v-for="(value, name) in values" :key="name">
              <h3>
                {{ name }}
              </h3>
              <template v-if="typeof value === 'object'">
                <p v-for="(message, index) in value" :key="index">
                  {{ message }}
                </p>
              </template>
              <p v-else>{{ value }}</p>
            </div>
          </template>
          <template v-else>
            <h3>
              {{ name }}
            </h3>
            <p>{{ values }}</p>
          </template>
        </div>

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="toggleMessage"> Fechar </v-btn>
        </template>
      </v-snackbar>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'Message',

  components: {},

  props: {},
  data: () => ({}),
  computed: {
    ...mapState('template', ['message']),
  },

  methods: {
    ...mapActions('template', ['toggleMessage']),
  },
}
</script>

<style></style>
