<template>
  <extrato-item
    :id="saidas ? 'saidas' : 'entradas'"
    :headers="headers"
    :items="saidas ? movimentacoesSaidasManuais : movimentacoesEntradasManuais"
    :title="title"
    :negative="saidas"
  >
    <template #valor="{ item }">
      {{ toReal(item.valor) }}
    </template></extrato-item
  >
</template>

<script>
import { mapGetters } from 'vuex'

import { toReal } from '@/mixins/convertion'

import ExtratoItem from './ExtratoItem'

export default {
  name: 'ExtratoTriagem',
  props: {
    saidas: {
      type: Boolean,
    },
  },
  mixins: [toReal],
  components: { ExtratoItem },
  data() {
    return {
      headers: [
        { text: 'Descricao', value: 'descricao' },
        { text: 'Valor', value: 'valor' },
        { text: 'Forma Pagamento', value: 'forma_pagamento' },
      ],
    }
  },
  computed: {
    ...mapGetters('caixa', [
      'movimentacoesEntradasManuais',
      'movimentacoesSaidasManuais',
    ]),

    title() {
      return this.saidas ? 'Saídas Manuais' : 'Entradas Manuais'
    },
  },
}
</script>

<style>
#entradas .v-card,
#saidas .v-card {
  border-width: 3px;
  border-style: solid;
  border-radius: 15px;
}

#saidas .v-card {
  border-color: #00113a;
}
#entradas .v-card {
  border-color: #00aa9e;
}
</style>
