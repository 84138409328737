<template>
  <v-row>
    <v-col>
      <v-card elevation="12">
        <v-card-title>
          <v-row align="center" justify="space-between" class="px-4">
            <v-col cols="auto">
              <span>{{ title }}</span>
            </v-col>
            <v-col cols="4">
              <v-text-field
                class="ml-4"
                v-model="search"
                label="Pesquisar"
                prepend-icon="mdi-magnify"
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-data-table :headers="headers" :items="items" :search="search">
            <template #item="{ item }">
              <tr>
                <td v-for="{ value } in headers" :key="value">
                  <span :class="getClass(value)">
                    <slot :name="value" :item="item">
                      {{ getValue(item, value) }}
                    </slot>
                  </span>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ExtratoTriagem',
  props: {
    items: {
      type: Array,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    negative: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      search: '',
    }
  },
  methods: {
    getValue(item, props) {
      return props.split('.').reduce((acc, prop) => acc[prop], item)
    },
    getClass(item) {
      if (item === 'valor') {
        return this.negative ? 'negative-value' : 'positive-value'
      }
    },
  },
}
</script>

<style scoped>
td {
  text-transform: uppercase;
}
</style>
