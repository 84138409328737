<template>
  <div>
    <h2 class="text-subtitle-1 primary--text">{{ title }}</h2>
    <div
      class="d-flex justify-space-between"
      v-for="[key, value] in Object.entries(totalAgrupadoPorFormaPagamento)"
      :key="key"
    >
      <span>{{ key }}</span>
      <span :class="value >= 0 ? 'positive-value' : 'negative-value'">{{
        toReal(value)
      }}</span>
    </div>
  </div>
</template>

<script>
import { toReal } from '@/mixins/convertion'

export default {
  name: 'ExtratoTotalFormaPagamento',
  mixins: [toReal],
  props: {
    title: {
      type: String,
      required: true,
    },
    totalAgrupadoPorFormaPagamento: {
      type: Object,
      required: true,
    },
    
  },
}
</script>

<style scoped>
span {
  text-transform: uppercase;
}
</style>
