<template>
  <v-row>
    <v-col>
      <v-card>
        <v-row align="center">
          <v-col cols="3">
            <v-card-title
              >Envelope Nº {{ caixaExtrato.caixa.numero }}</v-card-title
            >
          </v-col>
          <v-col>
            <v-card-text class="d-flex justify-space-around text-subtitle-1">
              <span>
                Aberto em:
                {{
                  caixaExtrato.caixa.data_hora_abertura | toLocaleDateTimeString
                }}
              </span>
              <span v-if="caixaExtrato.caixa.data_hora_fechamento">
                Fechado em:
                {{
                  caixaExtrato.caixa.data_hora_fechamento
                    | toLocaleDateTimeString
                }}
              </span>
              <span> Data/Hora Atual: {{ now | toLocaleDateTimeString }}</span>
              <span> Colaborador: {{ caixaExtrato.caixa.colaborador }}</span>
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { toLocaleDateTimeString } from '@/mixins/convertion'
import { mapState } from 'vuex'

export default {
  name: 'ExtratoHeader',
  data() {
    return {
      now: new Date(),
      timer: null,
    }
  },
  computed: {
    ...mapState('caixa', ['caixaExtrato']),
  },
  filters: {
    toLocaleDateTimeString,
  },
  created() {
    this.timer = setInterval(() => {
      this.now = new Date()
    }, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
}
</script>
