<template>
  <v-container fluid class="d-flex flex-column">
    <v-overlay v-model="loading">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <template v-if="!loading">
      <message />
      <!-- cabeçalho -->
      <extrato-header class="mt-1 mb-4" />
      <!-- serviços concluídos -->
      <extrato-movimentacao-servico
        @trocarFormaPagemento="trocarFormaPagemento"
        @trocarTransportadora="trocarTransportadora"
        @buscarTransportadoras="buscarTransportadoras"
        @setUpTransportadoras="setUpTransportadoras"
      />
      <!-- serviços cancelados -->
      <extrato-movimentacao-servico cancelados />
      <!-- entradas -->
      <extrato-movimentacao-manual />
      <!-- saídas -->
      <extrato-movimentacao-manual saidas />
      <!-- total a vista e a prazo -->
      <extrato-total />
      <!-- assinatura -->
      <extrato-assinatura />
    </template>
  </v-container>
</template>

<script>
import { toReal } from '@/mixins/convertion.js'

import ExtratoAssinatura from '@/components/financeiro/caixa/extrato/ExtratoAssinatura'
import ExtratoHeader from '@/components/financeiro/caixa/extrato/ExtratoHeader'
import ExtratoMovimentacaoServico from '@/components/financeiro/caixa/extrato/ExtratoMovimentacaoServico.vue'
import ExtratoMovimentacaoManual from '@/components/financeiro/caixa/extrato/ExtratoMovimentacaoManual.vue'
import ExtratoTotal from '@/components/financeiro/caixa/extrato/ExtratoTotal.vue'
import { mapActions, mapMutations, mapState } from 'vuex'

import Message from '../../../layouts/full-layout/message/Message.vue'
export default {
  name: 'CaixaExtrato',
  props: {
    caixaID: {
      type: Number,
      required: true,
    },
  },
  mixins: [toReal],
  components: {
    ExtratoAssinatura,
    ExtratoHeader,
    ExtratoMovimentacaoServico,
    ExtratoMovimentacaoManual,
    ExtratoTotal,
    Message,
  },
  data() {
    return {
      loading: false,
      agendamento: '',
      item: {
        forma_pagamento: 'PIX',
      },
    }
  },
  computed: {
    ...mapState('caixa', ['caixaAberto']),
    ...mapState('formaPagamento', ['formasPagamento']),
    ...mapState('transportadoras', ['transportadoras']),
  },
  methods: {
    ...mapActions('caixa', [
      'getCaixaExtrato',
      'editMovimentacao',
      'changeTransportadora',
    ]),
    ...mapMutations('caixa', ['SET_CAIXA_EXTRATO']),
    ...mapActions('formaPagamento', ['getFormasPagamento']),
    ...mapActions('transportadoras', ['getTransportadorasSimple']),
    ...mapMutations('transportadoras', [
      'concatTransportadoras',
      'clearTransportadoras',
    ]),
    ...mapActions('template', ['errorMessage', 'successMessage']),
    async buscarTransportadoras(param) {
      const do_fornecedor = await this.getTransportadorasSimple({
        empresa: { public_id: this.agendamento.fornecedor_public_id },
        params: {
          pesquisa: param,
        },
      })
      // this.concatTransportadoras(do_fornecedor)
      this.concatTransportadoras(
        do_fornecedor.filter(
          (t) =>
            !this.transportadoras
              .map((i) => i.public_id)
              .includes(t.transportadora_id)
        )
      )
      const do_cliente = await this.getTransportadorasSimple({
        empresa: { public_id: this.agendamento.cliente_public_id },
        params: {
          pesquisa: param,
        },
      })
      // this.concatTransportadoras(do_cliente)
      this.concatTransportadoras(
        do_cliente.filter(
          (t) =>
            !this.transportadoras
              .map((i) => i.public_id)
              .includes(t.transportadora_id)
        )
      )
      const da_empresa_atual = await this.getTransportadorasSimple({
        empresa: this.empresaAtual,
        params: {
          pesquisa: param,
        },
      })
      // this.concatTransportadoras(da_empresa_atual)
      this.concatTransportadoras(
        da_empresa_atual.filter(
          (t) =>
            !this.transportadoras
              .map((i) => i.public_id)
              .includes(t.transportadora_id)
        )
      )
    },
    async setUpTransportadoras(item) {
      if (!this.agendamento || this.agendamento.public_id != item.public_id) {
        this.clearTransportadoras()
        this.agendamento = item
      }
    },
    async trocarFormaPagemento(item) {
      try {
        await this.editMovimentacao({
          id: item.id,
          forma_pagamento: item.forma_pagamento.public_id,
        })
        this.successMessage(
          `Forma de pagamento alterada para ${item.forma_pagamento.descricao}`
        )
      } catch (error) {
        if (error.response) {
          this.errorMessage(error.response.data)
          return
        }
        if (error.message) {
          this.errorMessage(error.message)
          return
        }
        this.errorMessage(error)
      }
    },
    async trocarTransportadora(item) {
      console.log(item)
      try {
        await this.changeTransportadora({
          servico_triado: { public_id: item.servico_public_id },
          transportadora: item.transportadora,
        })
        this.successMessage(
          `Transportadora alterada para ${
            item.transportadora.business_name
          } - ${item.transportadora.cnpj || item.transportadora.cpf}`
        )
      } catch (error) {
        if (error.response) {
          this.errorMessage(error.response.data)
          return
        }
        if (error.message) {
          this.errorMessage(error.message)
          return
        }
        this.errorMessage(error)
      }
    },
  },

  async created() {
    if (this.caixaID) {
      this.loading = true
      await this.getFormasPagamento()
      await this.getCaixaExtrato({ caixaID: this.caixaID })
      this.loading = false
    }
  },
}
</script>
